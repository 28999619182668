import styled from 'styled-components';
import { greenColor } from '../Styled/color';

export const Container = styled.div`
    height: 1em;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
`;

export const Filler = styled.div`
    height: 100%;
    width: ${props => props.completed ? `${props.completed}%` : `0%`};
    background-color: ${props => props.bgColor ? `${props.bgColor}` : `${greenColor}`};
    border-radius: inherit;
    text-align: right;
`;

export const Label = styled.span`
    padding: 5px;
    color: white;
    font-weight: bold;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: -5px;
`;