import React, { memo } from "react";
import Content from "../../Components/Content";
import ProgressBar from "../../Components/ProgressBar";
import Avatar from "./avatar";
import { Card, Column, Floating, Skills, Flex, SkillIcon, Title } from "../../Components/Styled";
import { Svg } from "../../Components/icons";
import RadarChart from "react-animate-radar-chart";
import { textColor } from "../../Components/Styled/color";
import Timeline from '../../Components/Timeline';
import Blockquote from '../../Components/Blockquote';

const Profile = () => {

  const skills = ['laravel', 'serverless', 'nestjs', 'react', 'vue-dot-js', 'wordpress', 'amazonaws', 'amazondynamodb', 'mysql', 'php', 'typescript', 'swift'];
  
  const data = [
    {
      name: "Web",
      value: 0.6,
    },
    {
      name: "Mobile",
      value: 0.6
    },
    {
      name: "DevOps",
      value: 0.7,
    },
    {
      name: "Database",
      value: 0.8
    },
    {
      name: "Backend",
      value: 0.8,
    },
  ];

  const colors = {
    lineColor: textColor,
    innerColor: "#42b681c7",
    outerColor: "transparent",
    fontColor: textColor,
    borderColor: "rgb(109,200,236)",
  };

  return (
    <div className="Profile">
      <Flex>
        <Card>
          <Avatar />
          <Column>
            <Content title={'Who'} desc={'Anthony Leung'} />
            <Content title={'Find'} desc={'pandaofc@gmail.com'} />
          </Column>
          <Column>
            <Content title={'Work'} desc={'Spaceship'} />
            <Content title={'Doing'} desc={'Senior Software Engineer'} />
          </Column>
          <Floating right top>
            <a href="https://www.linkedin.com/in/leung-kam-lai-anthony-7707bb90/" target="_blank" alt="linkedin"><Svg name='linkedin' /></a>
          </Floating>
        </Card>
        <Card center>
          <Title>About Me</Title>
          <Blockquote content="An experienced programmer is able to offer solid skills in IT research as well as development and support fields. My expertise in PHP allows me to efficiently establish a variety of user-friendly websites for different clients." />
        </Card>
        <Card half center>
          <RadarChart
            data={data}
            showData={true}
            size={300}
            colors={colors}
          />
        </Card>
        <Card half column>
          <Title>
            {'SKILLS'}
          </Title>
          <Skills>
            {skills.map((name) => {
              return <SkillIcon title={name}>
                <Svg name={name} />
              </SkillIcon>
            })}
          </Skills>
          <div>
            <ProgressBar bgColor='#787bb3' completed='90' title='PHP' />
            <ProgressBar bgColor='#df8e02' completed='80' title='MySQL' />
            <ProgressBar bgColor='#6ca65e' completed='70' title='JavaScript' />
            <ProgressBar bgColor='#efd71e' completed='65' title='TypeScript' />
            <ProgressBar bgColor='#f73d27' completed='60' title='Swift' />
          </div>
        </Card>
        <Card>
          <Timeline />
        </Card>
      </Flex>
    </div>
  );
};

export default memo(Profile);
