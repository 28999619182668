import React from "react";
import { Title, Row } from "../Styled";

const Content = ({ title, desc}) => {
  return (
    <Row>
      <Title>{title}</Title>
      <span>{desc}</span>
    </Row>
  );
};

export default Content;
