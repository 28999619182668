import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Profile from './Views/Profile';
// import Demo from './Views/Demo';
import Structure from './Components/Structure';

const Router = () => {
  /* Route for Special user */
  // const PrivateRoute = ({ component: Component, ...rest }) => {
  //   return (
  //     <Route {...rest}
  //       render={(props) => true ? <Component {...props} /> : <Redirect to="/" />}
  //     />
  //   );
  // };
  
  /* Route for Admin only */
  // const AdminRoute = ({ component: Component, ...rest }) => {
  //   return (
  //     <Route {...rest}
  //       render={(props) => true ? <Component {...props} /> : <Redirect to="/" />}
  //     />
  //   );
  // };

  return (
    <BrowserRouter basename={'/'}>
      <Structure>
        {/* <Switch> */}
          <Route exact path="/" component={Profile} />
          {/* <Route exact path="/demo/:ref?" component={Demo} /> */}
          {/* <Redirect to="/" /> */}
        {/* </Switch> */}
      </Structure>
    </BrowserRouter>
  );

}
export default Router;
