import React, { useState } from 'react';
import './style.scss';

const timelineData = [
    {
        text: 'Development a logistics e-commerce system, mainly responsible for background development and system design (Include datebase schema design).',
        tech: ['Laravel', 'React', 'Nestjs', 'ExpressJs'],
        date: 'Now',
        category: {
            tag: 'WORKING',
            color: '#57d7dd'
        },
        link: {
            url: 'https://hk.spaceshipapp.com',
            text: 'Spaceship'
        },
        logo: 'spaceship.png'
    },
    {
        text: 'Develop IOS keyboard extension, and system design, main position is using nextjs to development web app and using swiftUI to development keyboard.',
        tech: ['Swift', 'Serverless', 'React-native', 'Nextjs', 'DynamoDB'],
        date: 'Now',
        category: {
            tag: 'WORKING',
            color: '#ff8974'
        },
        link: {
            url: 'https://apps.apple.com/us/app/chatout/id1531995047',
            text: 'Chatout'
        },
        logo: 'chatout.jpg'
    },
    {
        text: 'Maintain E-commerce system, clear and understand the process and operation of e-commerce, include design with POS, ERP, WMS system',
        tech: ['PHP', 'MySQL', 'JQuery', 'vue'],
        date: 'March 31 2019',
        category: {
            tag: 'Served',
            color: '#00acee'
        },
        link: {
            url: 'https://www.yohohongkong.com/',
            text: 'Yoho Hong Kong Limited'
        },
        logo: 'yoho.png'
    },
    {
        text: 'Using PHP Sylius as a base and MYSQL as database to set up an online-shop platform and backend system for a company.',
        date: 'October 2016',
        tech: ['Symfony', 'MySQL', 'Sylius', 'scss'],
        category: {
            tag: 'Served',
            color: '#28a745'
        },
        link: {
            url:
                '#',
            text: 'BenePanda (First version)'
        },
        logo: 'benepanda.jpeg'
    },
    {
        text: 'Coding an IOS program with the team by using AWS and SWIFT for trading kids’ books online platform with AWS DynamoDB as database in which the users can register and log in with Facebook and Google+.',
        date: 'October 2016',
        tech: ['Swift'],
        category: {
            tag: 'Served',
            color: '#28a745'
        },
        link: {
            url:
                '#',
            text: 'Morus'
        },
        logo: 'morus.jpg'
    },
    {
        text: '',
        date: 'April 05 2015',
        category: {
            tag: 'graduation',
            color: '#018f69'
        },
        link: {
            url: 'https://www.ntust.edu.tw/',
            text: 'National Taiwan University of Science and Technology'
        },
        logo: 'ntust.png'
    }
]

const TimelineItem = ({ data }) => {

    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);

    return (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <span className="tag" style={{ background: data.category.color }}>
                    {data.category.tag}
                </span>
                <time>{data.date}</time>
                {data.link && (
                    <a
                        href={data.link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.link.text}
                    </a>
                )}
                <div class="flip-container">
                    <div
                        className={hovered ? 'preserve-card flipped' : 'preserve-card'}
                        onMouseEnter={toggleHover}
                        onMouseLeave={toggleHover}
                    >
                        <div class="content front">
                           <img src={require(`../../Assets/images/${data.logo}`)}/>
                        </div>
                        <div class="content back">
                            <p>{data.text}</p>
                            {data.tech && (<p>Using Tech: {data.tech.join()} </p>)}
                        </div>
                    </div>
                </div>
                <span className="circle" />
            </div>
        </div>
    )
};

const Timeline = () =>
    timelineData.length > 0 && (
        <div className="timeline-container">
            {timelineData.map((data, idx) => (
                <TimelineItem data={data} key={idx} />
            ))}
        </div>
    );

export default Timeline;