import styled from 'styled-components';
import { greenColor } from '../Styled/color';

export const Blockquote = styled.blockquote`
    position: relative;
    p {
        text-align: justify;
        padding: 0 2em;
        font-size: 1.1em;
    }
    ::before {
        font-family: Georgia,serif;
        content: "“";
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2.5em;
        color: #e3e3e3;
        font-weight: 400;
    }
    ::after {
        font-family: Georgia,serif;
        content: "”";
        position: absolute;
        top: 25px;
        line-height: 100px;
        right: 0;
        font-size: 50px;
        color: #e3e3e3;
        font-weight: 400;
    }
`;