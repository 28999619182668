import React from "react";
import { AvatarContent, AvatarImage, AvatarStatus } from "../../Components/Styled";

const mySelf = require('../../Assets/images/avatar.jpeg');
const Avatar = () => {

    return (
        <AvatarContent>
            <AvatarImage src={mySelf} alt="avatar" />
            <AvatarStatus>{'Survive'}</AvatarStatus>
        </AvatarContent>
    );
};

export default Avatar;