import { isNil, set, get, cloneDeep, concat, isEmpty } from 'lodash';
import { SET_DEMO_REDUCER, PUSH_DEMO_REDUCER, CLEAR_DEMO_REDUCER } from './../type';

const initState = { text: "", count: 0 };

const demo = (state = cloneDeep(initState), action) => {
  /* Extract all action's data */
  const { target, type, data } = action;

  /* Handle no target */
  if (isNil(target)) return state;

  /* Handle target by Type */
  let newState = cloneDeep(state);
  switch (type) {
    case SET_DEMO_REDUCER: {
      set(newState, target, data);
      return newState;
    }
    case PUSH_DEMO_REDUCER: {
      let temp = get(newState, target);
      if (Array.isArray(temp)) {
        if (Array.isArray(data)) temp = concat([], temp, data);
        else temp = temp.push(data);
      } return newState;
    }
    case CLEAR_DEMO_REDUCER: {
      if (isEmpty(target)) newState = cloneDeep(initState)
      else set(newState, target, cloneDeep(get(initState, target)));
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default demo;
