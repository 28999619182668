import React from 'react';
import { Icon } from '../Styled';

export const Svg = ({ name, ...rest }) => {
  const ImportedIconRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../Assets/images/icons/${name}.svg`)).default;
      } catch (err) {
        // throw err;
        return null;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <Icon><ImportedIcon {...rest} /></Icon>;
  }
  return null;
};