import React from "react";
import { Container } from "../Styled";
import Header from "./header";

const Structure = ({ children }) => {
  return (
    <div className="structure">
      {/* <Header /> */}
      <Container>
        {children}
      </Container>
    </div>
  );
};

export default Structure;
