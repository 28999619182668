import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import Router from './router';
import reduxData from './Reducers';
import registerServiceWorker from './registerServiceWorker';
import './i18n';
import './Assets/styles/style.scss';
import { GlobalStyle } from './Components/Styled';

const cssRule = `
  font-size: 60px;
  font-weight: bold;
  color: #000000;
  text-shadow: 1px 1px 5px #ffffff;
  filter: dropshadow(color=#ffffff, offx=1, offy=1);
`;
setTimeout(console.log.bind(console, "%cHello Pandaofc", cssRule), 0);

const store = createStore(
  reduxData,
  applyMiddleware(
    thunkMiddleware,
    createLogger(),
  ),
);

ReactDOM.render(
  <Suspense fallback="loading">
    <GlobalStyle />
    <Provider store={store}>
      <Router />
    </Provider>
  </Suspense>
, document.getElementById('root'));

registerServiceWorker();