import React from "react";
import { Container, Filler, Label } from "./styled";
import { SubTitle } from '../Styled'

const ProgressBar = (props) => {
    const { bgColor, completed, title } = props;

    return (
        <div>
            <SubTitle>{title}</SubTitle>
            <Container>
                <Filler completed={completed} bgColor={bgColor}>
                    <Label>{`${completed}%`}</Label>
                </Filler>
            </Container>
        </div>
    );
};

export default ProgressBar;