import React from "react";
import { Blockquote as Div } from "./styled";

const Blockquote = ({ content }) => {
  return (
    <Div>
      <p>{content}</p>
    </Div>
  );
};

export default Blockquote;
