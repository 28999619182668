import styled, { createGlobalStyle } from 'styled-components';
import { backgroundColor, primaryColor, cardBackgroundColor, textColor, greenColor } from './color';

export const mediumWidth =  `768px`;
const minWidth =  `375px`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 1em;
    background: ${backgroundColor};
    color: ${textColor};
    font-family: Open-Sans, Helvetica, Sans-Serif;
    font-size: 16px;
    min-width: 375px;
  }
  * {    
    box-sizing: border-box;
    letter-spacing: 1px;
  }
`;

export const Container = styled.div`
  max-width: 1350px;
  margin: auto;
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  gap: 1em;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const DemoTitle = styled.h1`
  font-size: 30px;
`;

export const Title = styled.h4`
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.2em;
  color: ${primaryColor};
  width: 100%;
`;

export const SubTitle = styled.h4`
  font-size: 1em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
  color: white;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  border-radius: 15px;
  padding: 2em;
  background: ${cardBackgroundColor};
  flex-wrap: wrap;
  position: relative;
  gap: 1em;
  ${props => props.column ? `flex-direction: column` : ``};
  ${props => props.center ? `align-items: center` : ``};
  ${props => props.half ? `flex: 1` : `width: 100%`};
  @media only screen and (max-width: ${mediumWidth}) {
    width: 100%;
    flex: auto;
    gap: 1em;
  }
`;

export const Floating = styled.div`
  position: absolute;
  ${props => props.right ? `right: 1.5em` : ''};
  ${props => props.top ? `top: 1.5em` : ''};
`;

export const AvatarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 2em;
  @media only screen and (max-width: ${mediumWidth}) {
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction:column;
  @media only screen and (max-width: ${mediumWidth}) {
    width: 100%;
  }
`;

export const Row = styled.div`
  margin-bottom: 1em;
`;

export const AvatarImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: relative;
  background: #2c3033;
`;

export const AvatarStatus = styled.div`
  position: absolute;
  bottom: 0px;
  text-align: center;
  margin: 0 auto;
  color: white;
  background: ${greenColor};
  border-radius: 10px;
  padding: 2px 10px;
  font-weight: bold;
  border: 5px solid ${cardBackgroundColor};
`;

export const Icon = styled.div`
  width:30px;
  & path {
    fill: white;
    &:hover{
      fill: ${primaryColor};
    }
  } 
`;

export const Skills = styled.div`
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const SkillIcon = styled.div`
  background: ${backgroundColor};
  padding: 0.3em 0.3em 0.1em 0.3em;
  border-radius: 5px;
`;